.confirmation-modal {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
  }
  
  .confirmation-modal h3 {
    text-align: center;
  }
  
  .confirmation-modal p {
    margin-bottom: 10px;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: center;
  }
  
  .modal-buttons button {
    margin: 0 10px;
    padding: 10px 20px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .modal-buttons button:hover {
    background-color: #45a049;
  }
  