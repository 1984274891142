.booking-container {
    max-width: 400px;
    margin: 7% auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
  }
  
  .booking-heading {
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  .form-label {
    display: block;
    font-weight: bold;
  }
  
  .form-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .booking-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .booking-button:hover {
    background-color: #45a049;
  }
  