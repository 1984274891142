.home-container1 {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, 
    rgb(245,246,246) 30%, rgb(245,246,246) 70%, rgba(0, 0, 0, 0) 100%), 
    url("../../image/Background_Header.jpg");
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
   padding-bottom: 5%;
}
  
.home-title{
    margin: 0px;
  }

.home1{
    margin-left: 30%;
    padding-top: 5%;
    margin-right:30%;
  }

  .bookingButton {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }
  
  .bookingButton:hover {
    background-color: #0056b3;
  }
  
  .buttonLink {
    text-decoration: none;
    color: #fff;
  }
  
  .home-container2 {
    background-image: linear-gradient(to right, 
    rgb(245,246,246)30%, rgb(245,246,246) 10%, rgba(0, 0, 0, 0) 100%), 
    url("../../image/massage.png");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: right;
   padding-bottom: 5%;
}

.home2{
    margin-left: 7%;
    padding-top: 5%;
    margin-right:60%;
  }

  .home-container3 {
    background-image: linear-gradient(to right, 
    rgba(0, 0, 0, 0)30%, rgb(245,246,246) 10%, rgb(245,246,246) 100%), 
    url("../../image/Greentea.png");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: left;
   padding-bottom: 5%;
}

.home3{
    margin-left: 50%;
    padding-top: 5%;
    margin-right:10%;
  }

  .home-container4 {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, 
    rgb(245,246,246) 30%, rgb(245,246,246) 70%, rgba(0, 0, 0, 0) 100%), 
    url("../../image/Background_Home3.jpg");
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
   padding-bottom: 5%;
}
  
.home4{
    margin-left: 30%;
    padding-top: 5%;
    margin-right:30%;
  }