.account-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .account-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .account-container .form-group {
    margin-bottom: 10px;
  }
  
  .account-container label {
    display: block;
    margin-bottom: 5px;
  }
  
  .account-container input {
    width: 95%;
    padding: 10px;
  }
  
  .account-container button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .account-container button:hover {
    background-color: #45a049;
  }
  
  .account-container p {
    margin-bottom: 10px;
  }
  
  .account-container button.confirm {
    background-color: #f44336;
  }
  
  .account-container button.confirm:hover {
    background-color: #d32f2f;
  }
  
  
  .booking-container {
    flex-basis: 100%;
    margin-top: 20px;
  }
  
  .booking-container h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .booking-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .booking-list li {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
  }
  
  .booking-title {
    font-weight: bold;
  }
  
  .booking-info {
    display: block;
    margin-bottom: 5px;
  }
  
  .booking-actions {
    margin-top: 10px;
  }
  
  .booking-update-form {
    margin-top: 10px;
  }
  
  .booking-update-form input {
    margin-right: 5px;
    margin-bottom: 5px;
  }
  
  .main-container {
    display: flex;
    justify-content: space-around; 
  }
  
  .account-container,
  .booking-container {
    flex: 5; 
    margin: 10 10px; 
  }