.weather-container {
    background: #f5f7fa;
    border-radius: 5px;
    padding: 30px;
    width: 80%;
    margin: 5% auto;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .weather-container h2 {
    color: #37474F;
    margin-bottom: 20px;
    text-align: center;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  table thead th {
    padding: 10px;
    background: #2196F3;
    color: #ffffff;
    text-align: left;
  }
  
  table tbody td {
    padding: 10px;
    border: 1px solid #e0e0e0;
    text-align: center;
  }
  
  table tbody tr:nth-child(even) {
    background: #f0f0f0;
  }
  
  table tbody td img {
    width: 40px;
    height: 40px;
  }
  